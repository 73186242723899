import Carousel from 'react-material-ui-carousel'
import React from 'react'
import { useTranslation } from 'react-i18next';

import Header from '../../components/header/Header'
import About from '../about/About'
import Services from '../services/Services'
import Team from '../team/Team'

import carouselImg1 from '../../images/header-img.jpg';
import carouselImg2 from '../../images/family-law-9.jpg';

import './Home.scss';


function Home() {
  const { t } = useTranslation();

  var carouselItem = [
    {
      caption: t('image_caption_1'),
      small_caption: t('image_small_caption_1'),
      imageUrl: carouselImg1
    },
    {
      caption: t('image_caption_2'),
      small_caption: t('image_small_caption_2'),
      imageUrl: carouselImg2
    }
  ]

  return (
    <div id="home">
      <Header />
      <div className="carousel">
        <Carousel 
          animation='slide'
          timeout={800}
          stopAutoPlayOnHover={false}
          indicators={false}
        >
          {
            carouselItem.map( (item, i) => <Item key={i} carouselItem={item}/> )
          }
        </Carousel>
      </div>
      <About />
      <Services />
      <Team />
    </div>
  );
}

function Item(props){
  return (
    <div className="image-container">
      <div style={{backgroundImage: `url(${props.carouselItem.imageUrl})`}} className="header-image">
      </div>
      <div className="header-image-caption-wrapper">
        <h1 className="caption">{props.carouselItem.caption}</h1>
        <h2 className="small-caption">{props.carouselItem.small_caption}</h2>
      </div>
    </div>

  )
}

export default Home;
