
import Home from './pages/home/Home'
import CivilLaw from './pages/services/civil_law/CivilLaw'
import LandAquisition from './pages/services/land_aquisition/LandAquisition'
import MotorAccidentsClaim from './pages/services/motor_accidents_claim/MotorAccidentsClaim';
import FamilyLaw from './pages/services/family_law/FamilyLaw';
import CriminalSuits from './pages/services/criminal_suits/CriminalSuits';
import IntellectualProperty from './pages/services/intellectual_property/IntellectualProperty';
import Conveyance from './pages/services/conveyance/Conveyance';
import ConsumerDisputes from './pages/services/consumer_disputes/ConsumerDisputes';
import Lokayukta from './pages/services/lokayukta/Lokayukta';
import CommercialDisputes from './pages/services/commercial_disputes/CommercialDisputes';
import Disclaimer from './components/disclaimer/Disclaimer';
import TermsAndConditions from './components/terms_and_conditions/TermsAndConditions';
import LawOfContract from './pages/services/law_of_contract/LawOfContract';

import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
  
export const ROUTES = {
  "Home": {
    path: "/",
    component: Home
  },
  "CivilLaw": {
    path: "/services/civil_law",
    component: CivilLaw
  },
  "LandAquisition": {
    path: "/services/land_aquisition",
    component: LandAquisition
  },
  "MotorAccidentsClaim": {
    path: "/services/motor_accidents_claim",
    component: MotorAccidentsClaim
  },
  "FamilyLaw": {
    path: "/services/family_law",
    component: FamilyLaw
  },
  "CriminalSuits": {
    path: "/services/criminal_suits",
    component: CriminalSuits
  },
  "IntellectualProperty": {
    path: "/services/intellectual_property",
    component: IntellectualProperty
  },
  "Conveyance": {
    path: "/services/conveyance",
    component: Conveyance
  },
  "ConsumerDisputes": {
    path: "/services/cosumer_disputes",
    component: ConsumerDisputes
  },
  "Lokayukta": {
    path: "/services/lokayukta",
    component: Lokayukta
  },
  "CommercialDisputes": {
    path: "/services/commercial_disputes",
    component: CommercialDisputes
  },
  "LawOfContract": {
    path: "/services/law_of_contract",
    component: LawOfContract
  },
  "Disclaimer": {
    path: "/disclaimer",
    component: Disclaimer
  },
  "TermsAndConditions": {
    path: "/terms_and_conditions",
    component: TermsAndConditions
  }
}

export default function Routes() {
  const list = []
  for (const key in ROUTES) {
    if (Object.hasOwnProperty.call(ROUTES, key)) {
      list.push(
        <Route exact key={key} path={ROUTES[key].path} component={ROUTES[key].component} />
      )         
    }
  }

  return(
    <BrowserRouter>
      <Switch>
        {list}
      </Switch>
    </BrowserRouter>
  )
}