import React from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../routes'

import MediaCard from '../../components/card/Card'
import civilLawImage from '../../images/civil-law.jpg';
import LandAquistionImage from '../../images/land-aquisition-img.jpg';
import MotorAccidentImage from '../../images/motor-accident-7.jpeg';
import FamilyLawImage from '../../images/family-law-12.jpeg';
import CriminalSuitsImage from '../../images/criminal-law-2.jpg';
import IntellectualPropertyImage from '../../images/intellectual-property.jpg';
import ConveyanceLawImage from '../../images/conveyance-law.jpg';
import ConsumerDisputesImage from '../../images/consumer-disputes.jpg';
import LokayuktaImage from '../../images/lokayukta.jpg';
import CommercialDisputesImage from '../../images/commercial-disputes.jpg';
import LawOfContractImage from '../../images/law-of-contract.jpg';

import './Services.scss';

export default function Services() {

  const { t } = useTranslation();

  const SERVICES = [
    {
      title: t('services.civil_law.title'),
      text: t('services.civil_law.short_description'),
      image: civilLawImage,
      url: ROUTES["CivilLaw"].path
    },
    {
      title: t('services.land_aquisition.title'),
      text: t('services.land_aquisition.short_description'),
      image: LandAquistionImage,
      url: ROUTES["LandAquisition"].path
    },
    {
      title: t('services.motor_accidents_claim.title'),
      text: t('services.motor_accidents_claim.short_description'),
      image: MotorAccidentImage,
      url: ROUTES["MotorAccidentsClaim"].path
    },
    {
      title: t('services.family_law.title'),
      text: t('services.family_law.short_description'),
      image: FamilyLawImage,
      url: ROUTES["FamilyLaw"].path
    },
    {
      title: t('services.criminal_suits.title'),
      text: t('services.criminal_suits.short_description'),
      image: CriminalSuitsImage,
      url: ROUTES["CriminalSuits"].path
    },
    {
      title: t('services.intellectual_property.title'),
      text: t('services.intellectual_property.short_description'),
      image: IntellectualPropertyImage,
      url: ROUTES["IntellectualProperty"].path
    },
    {
      title: t('services.conveyance.title'),
      text: t('services.conveyance.short_description'),
      image: ConveyanceLawImage,
      url: ROUTES["Conveyance"].path
    },
    {
      title: t('services.consumer_disputes.title'),
      text: t('services.consumer_disputes.short_description'),
      image: ConsumerDisputesImage,
      url: ROUTES["ConsumerDisputes"].path
    },
    {
      title: t('services.lokayukta.title'),
      text: t('services.lokayukta.short_description'),
      image: LokayuktaImage,
      url: ROUTES["Lokayukta"].path
    },
    {
      title: t('services.commercial_disputes.title'),
      text: t('services.commercial_disputes.short_description'),
      image: CommercialDisputesImage,
      url: ROUTES["CommercialDisputes"].path
    },
    {
      title: t('services.law_of_contract.title'),
      text: t('services.law_of_contract.short_description'),
      image: LawOfContractImage,
      url: ROUTES["LawOfContract"].path
    },
    {
      title: "",
      text: "",
      image: "",
      url: ""
    }
  ]
   
  const list = SERVICES.map(function(service) {
    return (
      <MediaCard
        key={service.title}
        title={service.title}
        text={service.text}
        image={service.image}
        url={service.url}
      />
    )
  })

  return (
    <div id="services">
      <div data-aos="fade-up" >
        <div className="section-caption">
          <h2 className="custom-underline">
            {t('services.title')}
          </h2>
        </div>
        <div className="service-list">
          { list }
        </div>
      </div>
    </div>
  );
}


