import React from 'react';
import { useTranslation } from 'react-i18next';

import './About.scss';

export default function About() {

  const { t } = useTranslation();

  return(
    <div id='about' name='about'>
      <div data-aos="fade-up">
        <div className="section-caption">
          <h2 className="custom-underline">
            {t('about.title')}
          </h2>
        </div>
        <p>
          {t('about.description')}
        </p>
      </div>
    </div>
  )
}
